import React, { useEffect, useState } from "react";
import FactionIntro from "../components/faction/FactionIntro";
import { useHomeData } from "../context/HomeContext";
import { MutatingDots } from "react-loader-spinner";
import RoadMap from "../features/roadmap/home-v2";
import dataRoadmap from "../assets/fake-data/data-roadmap";
import FactionCta from "../components/factionCta";
import LeaderIntro from "../components/faction/LeaderIntro";
import {
  PrismicRichText,
  useFirstPrismicDocument,
  useSinglePrismicDocument,
} from "@prismicio/react";

function Pirates(props) {
  const { piratesData, setPiratesData } = useHomeData();
  const [isLoading, setIsLoading] = useState(!piratesData);

  const [document] = useSinglePrismicDocument("pirates");

  useEffect(() => {
    if (!piratesData && document) {
      setPiratesData(document);
      setIsLoading(false);
      console.log(piratesData);
    }
  }, [document, piratesData, setPiratesData]);

  useEffect(() => {});

  if (isLoading) {
    return (
      <div
        // put at the center of the screen
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <MutatingDots
          height="200"
          width="200"
          color="#dd4040"
          secondaryColor="#dd4040"
          radius="12.5"
          ariaLabel="mutating-dots-loading"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
        />
      </div>
    );
  }
  return (
    <div>
      <FactionIntro
        faction={piratesData?.data}
        factionSocials={piratesData?.data?.body?.find(
          (slice) => slice.slice_type === "footer_socials"
        )}
      />
      {
        // if there is a factionCta slice, render it
        piratesData?.data.body.find(
          (slice) => slice.slice_type === "faction_cta"
        ) && (
          <>
            <FactionCta
              data={piratesData?.data.body.find(
                (slice) => slice.slice_type === "faction_cta"
              )}
            />
          </>
        )
      }

      {piratesData.data.body.find(
        (slice) => slice.slice_type === "roadmap"
      ) && (
        <RoadMap
          data={dataRoadmap}
          newData={piratesData.data.body.find(
            (slice) => slice.slice_type === "roadmap"
          )}
        />
      )}

      {piratesData.data.body.find(
        (slice) => slice.slice_type === "leader_intro"
      ) && (
        <LeaderIntro
          // there can be many leader_intro slices, so we need to pass all of them in
          faction={piratesData.data.body.filter(
            (slice) => slice.slice_type === "leader_intro"
          )}
          leaderSocials={piratesData?.data?.body?.find(
            (slice) => slice.slice_type === "footer_socials"
          )}
        />
      )}

      {/* <Team data={dataTeam} />

      <Partner data={dataPartner} /> */}
    </div>
  );
}

export default Pirates;
