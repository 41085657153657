import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import "./styles.scss";

LoreItem.propTypes = {
  item: PropTypes.object,
};

function LoreItem(props) {
  const { item } = props;

  console.log(item);

  return (
    <Link to={`/lore/${item.uid}`}>
      <div className="post-item">
        <div className="wrap">
          {/* <Link to="#" className="tag">
          {item.category}
        </Link> */}
          <div className="image">
            <img
              src={item.data.preview_image.url}
              alt="Crybox"
              className="img-fluid"
            />
          </div>
          <div className="content-item">
            <div className="content">
              <div className="meta-post">
                <a href="#">{item.data.date[0].text}</a>
              </div>
              <div className="h8 title">
                <Link to="/blog-details">
                  {item.data.title[0].text.toUpperCase()}
                </Link>
              </div>
              {/* <p>{item.text}</p> */}
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
}

export default LoreItem;
