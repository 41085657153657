import React, { useState } from "react";
import PropTypes from "prop-types";
import "./styles.scss";

import { Scrollbar, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/scss";
import "swiper/scss/navigation";
import "swiper/scss/pagination";
import BlogItem from "./blog-item";
import Button from "../../components/button";
import LoreItem from "./blog-item/LoreItem";

LoreList.propTypes = {
  data: PropTypes.array,
};

function LoreList(props) {
  const { data, loreList } = props;


  React.useEffect(() => {
    console.log('lorelsit', loreList);
  }, [loreList]);

  const [dataBlock] = useState({
    subtitle: "BLOGS",
    title: "Lastest News",
  });

  return (
    <section className="tf-section blog">
      <div className="container">
        <div className="row">

          {(loreList && loreList.length === 0) && <div className="breadcrumbs center" data-aos="fade-up" data-aos-duration="800">
            <h2>SORRY NO LORE FOUND</h2>
            <p>Check back later :)</p>
            <Button title='Back To Homepage' path='/' />
          </div>}

          {loreList &&
            loreList.map((item) => (
              <div
                className="col-sm-6 col-md-3 mb-4 text-center"
              // when screen is small, style text center
              >
                {" "}
                {/* Added Bootstrap column class and margin-bottom */}
                <LoreItem item={item} />
              </div>
            ))}
          <div className="col-md-12">
            {/*  <div className="text-center mt-48">
              <Button title="Explore more" path="#" />
            </div> */}
          </div>
        </div>
      </div>
    </section>
  );
}

export default LoreList;
