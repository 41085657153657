import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import dataAbout from "../assets/fake-data/data-about";
import dataProject from "../assets/fake-data/dataProject";
import PageTitle from "../components/pagetitle";
import Project from "../features/project/home-v3";

import ModalVideo from "react-modal-video";
import "react-modal-video/scss/modal-video.scss";
import IncubatorDetails from "../features/about/IncubatorDetails";
import Team from "../features/team/home-v3";
import dataTeam from "../assets/fake-data/data-team";
import { useAllPrismicDocumentsByType } from "@prismicio/react";
import { client } from "../prismic";
import { useSinglePrismicDocument } from "@prismicio/react";
import { useHomeData } from "../context/HomeContext";
import { MutatingDots } from "react-loader-spinner";

function Incubator(props) {
  const { incubatorData, setIncubatorData } = useHomeData();
  const [isOpen, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(!incubatorData);
  // const [documents] = useAllPrismicDocumentsByType("incubator");
  const [document] = useSinglePrismicDocument("incubator");

  useEffect(() => {
    if (!incubatorData && document) {
      setIncubatorData(document);
      setIsLoading(false);
      console.log(incubatorData);
    }
  }, [document, incubatorData, setIncubatorData]);

  if (isLoading) {
    return (
      <div
        // put at the center of the screen
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <MutatingDots
          height="200"
          width="200"
          color="#dd4040"
          secondaryColor="#dd4040"
          radius="12.5"
          ariaLabel="mutating-dots-loading"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
        />
      </div>
    );
  }

  return (
    <div className="community">
      <PageTitle title="Incubator" />
      <section className="tf-section tf-video">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="content-about mb-51 mobie-40">
                <div
                  className="tf-title "
                  data-aos="fade-up"
                  data-aos-duration="800"
                >
                  <h3 className="title">
                    {incubatorData &&
                      incubatorData?.data?.incubator_header[0]?.text}
                  </h3>
                </div>
              </div>
            </div>

            <div className="col-md-12">
              <div className="video" data-aos="fade-up" data-aos-duration="800">
                <img
                  src={require("../assets/images/background/bg1roadmap.png")}
                  alt=""
                />
                <div className="wrap-video">
                  <Link
                    to="#"
                    className="popup-youtube"
                    onClick={() => setOpen(true)}
                  >
                    <svg
                      width="27"
                      height="35"
                      viewBox="0 0 27 35"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M27 17.0001L0 0.500061V35.0001L27 17.0001Z"
                        fill="#14C2A3"
                      />
                    </svg>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ModalVideo
          channel="youtube"
          autoplay
          isOpen={isOpen}
          videoId="wPEnL9sD9XE"
          onClose={() => setOpen(false)}
        />
      </section>
      <IncubatorDetails data={dataAbout} details={incubatorData} />
      <Team data={dataTeam} testimonials={incubatorData} />
      <Project data={dataProject} />
    </div>
  );
}

export default Incubator;
