import React from "react";
import PropTypes from "prop-types";

AboutItem.propTypes = {
  item: PropTypes.object,
};

function AboutItem(props) {
  const { item } = props;

  // console.log("ABOUT ITEM: ", item);

  return (
    <div key={item.id} className={`box-text corner-box ${item.class}`}>
      <div className="h7">{item.box_title[0].text.toUpperCase()}</div>
      <p>{item.box_description[0].text}</p>
    </div>
  );
}

export default AboutItem;
