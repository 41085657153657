import React, { useEffect, useState } from "react";
import PageTitle from "../components/pagetitle";
import { Link } from "react-router-dom";
import Team from "../features/team/home-v2";
import dataTeam from "../assets/fake-data/data-team";
import Partner from "../features/partner";
import dataPartner from "../assets/fake-data/data-partner";
import FactionIntro from "../components/faction/FactionIntro";
import { useHomeData } from "../context/HomeContext";
import { MutatingDots } from "react-loader-spinner";
import RoadMap from "../features/roadmap/home-v2";
import dataRoadmap from "../assets/fake-data/data-roadmap";
import FactionCta from "../components/factionCta";
import LeaderIntro from "../components/faction/LeaderIntro";
import fetchCryptoMonkzData from "../services/factionData";
import {
  PrismicRichText,
  useFirstPrismicDocument,
  useSinglePrismicDocument,
} from "@prismicio/react";

function Shuhari(props) {
  const { shuhariData, setShuhariData } = useHomeData();
  const [isLoading, setIsLoading] = useState(!shuhariData);
  const [powerCount, setPowerCount] = useState(0);

  const [document] = useSinglePrismicDocument("shuhari");

  useEffect(() => {
    if (!shuhariData && document) {
      setShuhariData(document);
      setIsLoading(false);
      console.log(shuhariData);
    }
  }, [document, shuhariData, setShuhariData]);

  useEffect(() => {
    fetchCryptoMonkzData("Shu Ha Ri").then((result) => {
      console.log("resultd", result);
      setPowerCount(result);
    });
  }, []);

  if (isLoading) {
    return (
      <div
        // put at the center of the screen
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <MutatingDots
          height="200"
          width="200"
          color="#dd4040"
          secondaryColor="#dd4040"
          radius="12.5"
          ariaLabel="mutating-dots-loading"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
        />
      </div>
    );
  }
  return (
    <div>
      <FactionIntro
        faction={shuhariData?.data}
        powerCount={powerCount}
        factionSocials={shuhariData?.data?.body?.find(
          (slice) => slice.slice_type === "footer_socials"
        )}
      />
      {
        // if there is a factionCta slice, render it
        shuhariData?.data.body.find(
          (slice) => slice.slice_type === "faction_cta"
        ) && (
          <>
            <FactionCta
              data={shuhariData?.data.body.find(
                (slice) => slice.slice_type === "faction_cta"
              )}
            />
          </>
        )
      }
      {shuhariData.data.body.find(
        (slice) => slice.slice_type === "roadmap"
      ) && (
        <RoadMap
          data={dataRoadmap}
          newData={shuhariData.data.body.find(
            (slice) => slice.slice_type === "roadmap"
          )}
        />
      )}

      {shuhariData.data.body.find(
        (slice) => slice.slice_type === "leader_intro"
      ) && (
        <LeaderIntro
          faction={shuhariData.data.body.find(
            (slice) => slice.slice_type === "leader_intro"
          )}
          leaderSocials={shuhariData?.data?.body?.find(
            (slice) => slice.slice_type === "footer_socials"
          )}
        />
      )}

      {/* <Team data={dataTeam} />

      <Partner data={dataPartner} /> */}
    </div>
  );
}

export default Shuhari;
