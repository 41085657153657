import React from "react";
import PropTypes from "prop-types";
import "./styles.scss";

SpecItem.propTypes = {
  item: PropTypes.object,
};

function SpecItem(props) {
  const { specItem } = props;

  // console.log("spec item: ", specItem);

  return (
    <div
      className={`box-text st2 corner-box ${specItem.active}`}
      style={{ marginBottom: "20px" }}
    >
      <div className="number">{specItem.number[0].text.toUpperCase()}</div>
      <div className="h7">{specItem.spec_title[0].text.toUpperCase()}</div>
      <p>{specItem.spec_description[0].text}</p>
    </div>
  );
}

export default SpecItem;
