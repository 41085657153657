import React, { useState } from "react";
import PropTypes from "prop-types";
import "swiper/scss";
import "swiper/scss/navigation";
import "swiper/scss/pagination";

import Button from "../button";

FactionCta.propTypes = {
  data: PropTypes.array,
};

function FactionCta(props) {
  const { data } = props;

  console.log('THIS IS THE FACTION DATA: ', data);

  const [dataBlock] = useState({
    subtitle: "Faction Call To Action",
    title: "Lastest News",
  });

  return (
    <section className="tf-section tf-about">
      <div className="container">
        <div className="row">
          <div className="">
            <div
              className="content-about mobie-40"
              data-aos="fade-up"
              data-aos-duration="800"
              style={{ textAlign: "center" }}
            >
              <div className="tf-title st2">
                <p className="h8 sub-title">
                  {data?.primary?.subhead[0]?.text}
                </p>
                <h4 className="title">{data?.primary?.title[0]?.text}
                </h4>
              </div>
              {data?.items.map((paragraph, index) => {
                return (
                  <p key={index}>
                    {paragraph?.paragraph[0]?.text}
                  </p>
                );
              })}
              <Button title={data?.primary?.cta_button_text[0].text} path={data?.primary?.cta_button?.url}
                targetBlank={true} rel="noopener noreferrer"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default FactionCta;
