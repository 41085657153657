import React from 'react';
import PageTitle from '../components/pagetitle';
import { Link } from 'react-router-dom';
import Team from '../features/team/home-v2';
import dataTeam from '../assets/fake-data/data-team';
import Partner from '../features/partner';
import dataPartner from '../assets/fake-data/data-partner';


function TeamDetail(props) {
    return (
        <div>
            <PageTitle title='Our Team' />

            <section className="tf-section team-detail ">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-5 col-lg-5 col-md-5">
                            <div className="image-details" data-aos="fade-right" data-aos-duration="800">
                                <img src={require('../assets/images/common/team12.png')} alt="" />
                            </div>
                        </div>
                        <div className="col-xl-7 col-lg-7 col-md-7">
                            <div className="info-detail" data-aos="fade-left" data-aos-duration="800">
                                <p className="sub">DEV DEVELOPEMENT</p>
                                <h4 className="name">John Smith</h4>
                                <div className="box">
                                    <div className="h7">Some Thing About him</div>
                                    <p>Purus, laoreet dui augue ut euismod. Elementum ante sociis volutpat tellus enim, 
                                        nisl consectetur mauris. Venenatis congue id quis eget viverra. Vestibulum, 
                                        justo, euismod congue feugiat eget fames gravida posuere. </p>
                                    <p>Purus, laoreet dui augue ut euismod. Elementum ante sociis volutpat tellus enim, nisl consectetur mauris. 
                                        Venenatis congue id quis eget viverra. Vestibulum, justo.</p>
                                </div> 
                                <div className="box contact">
                                    <div className="h7">Contact</div>
                                    <p>Cecilia Chapman 711-2880 Nulla St.Mankato United States
                                        (257) 563-7401</p>
                                    <p>Info.avitex@gmail.com</p>
                                </div> 
                                  
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Team data={dataTeam} />

            <Partner data={dataPartner} />
        </div>
    );
}

export default TeamDetail;