import React from "react";
import PropTypes from "prop-types";
import "./styles.scss";

PageTitle.propTypes = {
  title: PropTypes.string,
};

function PageTitle(props) {
  const { title, powerCount } = props;
  console.log("powerCount", powerCount);

  return (
    <section className="page-title">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div
              className="breadcrumbs"
              data-aos="zoom-in"
              data-aos-duration="800"
            >
              <h3>{title}</h3>
              {powerCount && (
                <p style={{ color: "#fff" }}>Power: {powerCount}</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default PageTitle;
