import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import "./App.scss";
import ScrollToTop from "./ScrollToTop";
import { PrismicProvider, PrismicToolbar } from "@prismicio/react";
import { client, repositoryName } from "./prismic";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.Fragment>
    <BrowserRouter>
      <PrismicProvider client={client}>
        <ScrollToTop />
        <App />
        <PrismicToolbar repositoryName={repositoryName} />
      </PrismicProvider>
    </BrowserRouter>
  </React.Fragment>
);
