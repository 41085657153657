import React, { useEffect, useState } from "react";
import dataProject from "../assets/fake-data/dataProject";
import PageTitle from "../components/pagetitle";
import Project from "../features/project/nftitem";

import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useAccount } from "wagmi";

function OwnersDashboard(props) {
  const navigate = useNavigate();
  let date = new Date("Feb 04 2022").getTime();
  let today = new Date().getTime();
  let msDay = 24 * 60 * 60 * 1000; // milliseconds per day

  let days = Math.floor((today - date) / msDay);

  const [nftsWithMetaData, setNftsWithMetaData] = useState([]);
  const [data, setData] = useState([]);
  const [totalChi, setTotalChi] = useState(0);
  const [totalBalance, setTotalBalance] = useState(0);

  const { address, isConnecting, isDisconnected, isConnected } = useAccount();

  useEffect(() => {
    if (!address) {
      navigate("/");
    }
  }, [address, isDisconnected, isConnected]);

  useEffect(() => {
    load()
      .then((data) => getMetaData(data))
      .then((metaData) => setNftsWithMetaData(metaData));
  }, []);

  const load = async () => {
    const results = await fetch(
      `https://dev.cryptomonkz.io/api/owner/${address}` //add ${address} for your own wallet
    );
    const data = await results.json();
    setData(data);
    return data;
  };

  const getMetaData = async (ids) => {
    const results = [];
    for (let i = 0; i < ids.length; i++) {
      const id = ids[i];
      const metaData = await fetch(
        `https://dev.cryptomonkz.io/api/metadata/${id}.json`
      );
      const data = await metaData.json();
      results.push(data);
      console.log("NFTS: ", results);
    }
    return results;
  };

  const total = async (e) => {
    let sumChi = 0;
    for (let i = 0; i < nftsWithMetaData?.length; i++) {
      let nft = nftsWithMetaData[i].attributes;
      let currentChiVal = nft.find(({ trait_type }) => trait_type === "Chi");
      console.log("currentChiVal: ", currentChiVal);
      let val = currentChiVal.value;
      sumChi += val;
    }
    setTotalChi(sumChi);
    setTotalBalance(sumChi * days);
  };
  useEffect(() => {
    total(nftsWithMetaData);

    console.log("total chi: ", totalChi);
    console.log("total balance: ", totalBalance);
    console.log("nfts with metadata: ", nftsWithMetaData);
  }, [nftsWithMetaData]);

  return (
    <div className="page-nft">
      <PageTitle title="OWNERS DASHBOARD" />
      <section className="chi p-3">
        <div className="wrapper">
          <div style={{ display: "flex", justifyContent: "center" }}>
            {/* <div>
              <h1 className="title-other">chi</h1>
            </div> */}
            <ul style={{ textAlign: "center" }}>
              <li
                style={{
                  marginBottom: "8px",
                  fontFamily: "kremlin",
                  fontSize: "25px",
                }}
              >
                CURRENT BALANCES
              </li>
              <li
                className="block-name"
                style={{
                  fontSize: "15px",
                  margin: "0",
                  fontWeight: "200",
                  fontFamily: "kremlin",
                }}
              >
                TOTAL OF{" "}
                <span style={{ color: "#dd4040" }}>{data?.length}</span> MONKS
              </li>
              <li
                className="block-name"
                style={{
                  fontSize: "15px",
                  margin: "0px",
                  fontWeight: "200",
                  fontFamily: "kremlin",
                }}
              >
                DAILY EARNED{" "}
                <span style={{ color: "#dd4040" }}>{totalChi}</span> CHI
              </li>
              <li
                className="block-name"
                style={{
                  fontSize: "15px",
                  margin: "0px",
                  fontWeight: "200",
                  fontFamily: "kremlin",
                }}
              >
                TOTAL BALANCE{" "}
                <span style={{ color: "#dd4040" }}>{totalBalance}</span> CHI
              </li>
            </ul>
          </div>
        </div>
      </section>

      <Project data={nftsWithMetaData} />
      {/* <Button
        title="click me"
        onClick={() => fetchUserNFTs(account[0])}
      ></Button> */}
    </div>
  );
}

export default OwnersDashboard;
