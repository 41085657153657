import React, { useState } from "react";
import PropTypes from "prop-types";
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Navigation, Thumbs } from "swiper";
import "swiper/scss";
import "swiper/scss/navigation";
import "swiper/scss/pagination";

// import TeamItem from '../team-item';
import { Link } from "react-router-dom";

Team.propTypes = {
  data: PropTypes.array,
};

function Team(props) {
  const { data, testimonials } = props;

  console.log("TESTIMINALS", testimonials);

  const bodyData = testimonials?.data?.body[0]?.primary;
  // console.log(bodyData, "body");
  const [dataBlock] = useState({
    subtitle: bodyData?.sub_head[0]?.text.toUpperCase(),
    title: bodyData?.header1[0]?.text.toUpperCase(),
    desc: bodyData?.aboutonehead[0].text.toUpperCase(),
    text: bodyData?.aboutonedescription[0].text.toUpperCase(),
    desc1: bodyData?.abouttwohead[0].text.toUpperCase(),
    text1: bodyData?.abouttwodescription[0].text.toUpperCase(),
  });

  const [thumbsSwiper, setThumbsSwiper] = useState(null);

  if (!testimonials || !bodyData) {
    return <div>Loading...</div>;
  }

  return (
    <section className="tf-section tf-team gallery">
      <div className="container">
        <div className="row">
          <div className="col-xl-5 col-md-12">
            <div
              className="tf-title st2 mb-49"
              data-aos="fade-up"
              data-aos-duration="800"
            >
              <p className="h8 sub-title">
                {bodyData?.sub_head[0]?.text?.toUpperCase() ||
                  "elevate yourself"}
              </p>
              <h4 className="title">
                {bodyData?.header1[0]?.text?.toUpperCase() || "testimonials"}
              </h4>
            </div>
            <div
              className="content mobie-30 mb-40"
              data-aos="fade-up"
              data-aos-duration="800"
            >
              <div className="h7 txt-tranf-cap m-b16">
                {bodyData?.aboutonehead[0]?.text?.toUpperCase() || "Who are we"}
              </div>
              <p>{bodyData?.aboutonedescription[0]?.text || "test"}</p>
            </div>

            <div
              className="content mobie-30"
              data-aos="fade-up"
              data-aos-duration="800"
            >
              <div className="h7 txt-tranf-cap m-b16">
                {bodyData?.abouttwohead[0]?.text?.toUpperCase() || "Who are we"}
              </div>
              <p>{bodyData?.abouttwodescription[0]?.text || "testimonials"}</p>
            </div>
          </div>
          <div className="col-xl-7  col-md-12">
            <div className="gallery">
              <Swiper
                style={{
                  "--swiper-navigation-color": "#fff",
                  "--swiper-pagination-color": "#fff",
                }}
                spaceBetween={10}
                navigation={true}
                thumbs={{ swiper: thumbsSwiper }}
                modules={[FreeMode, Navigation, Thumbs]}
                className="gallery-slider"
              >
                {testimonials?.data?.body[0].items?.map((item, index) => (
                  <SwiperSlide key={index}>
                    <div className="team-box">
                      <img src={item.testimonial_img.url} alt="" />
                      <div className="content">
                        <h5>
                          <Link to="#">{item.name[0].text}</Link>
                        </h5>
                        <p>{item.job_title[0].text}</p>
                        <p className="desc">
                          "{item.testimonial_text[0].text}"
                        </p>
                        <ul className="social">
                          {/* {item.listsocial.map((icon) => (
                            <li key={icon.id}>
                              <Link to={icon.link}>
                                <i className={icon.icon}></i>
                              </Link>
                            </li>
                          ))} */}
                        </ul>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
              <Swiper
                // onSwiper={setThumbsSwiper}
                spaceBetween={20}
                breakpoints={{
                  0: {
                    slidesPerView: 5,
                  },
                  767: {
                    slidesPerView: 7,
                  },
                  991: {
                    slidesPerView: 6,
                  },
                }}
                freeMode={true}
                watchSlidesProgress={true}
                modules={[FreeMode, Navigation, Thumbs]}
                className="gallery-thumbs"
              >
                {testimonials?.data.body[0].items?.map((item, index) => (
                  <SwiperSlide key={index}>
                    <div className="image">
                      <img src={item.testimonial_img.url} alt="" />
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Team;
