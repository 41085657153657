import { ConnectButton } from "@rainbow-me/rainbowkit";
import Button from "../components/button";
import { useNavigate } from "react-router-dom";

export const ConnectButtonCustom = () => {
  const navigate = useNavigate();
  return (
    <ConnectButton.Custom>
      {({
        account,
        chain,
        openAccountModal,
        openChainModal,
        openConnectModal,
        authenticationStatus,
        mounted,
      }) => {
        // Note: If your app doesn't use authentication, you
        // can remove all 'authenticationStatus' checks
        const ready = mounted && authenticationStatus !== "loading";
        const connected =
          ready &&
          account &&
          chain &&
          (!authenticationStatus || authenticationStatus === "authenticated");

        return (
          <div
            {...(!ready && {
              "aria-hidden": true,
              style: {
                opacity: 0,
                pointerEvents: "none",
                userSelect: "none",
                color: "red",
              },
            })}
          >
            {(() => {
              if (!connected) {
                return (
                  <button
                    onClick={openConnectModal}
                    type="button"
                    style={{
                      fontSize: "14px",
                      border: "2px solid #dd4040",
                      borderRadius: "4px",
                      background: "transparent",
                      color: "white",
                      padding: "5px 12px",
                      letterSpacing: "1px",
                      fontWeight: 200,
                      fontFamily: "kremlin",
                    }}
                  >
                    CONNECT WALLET
                  </button>
                );
              }

              if (chain.unsupported) {
                return (
                  <button
                    onClick={openChainModal}
                    type="button"
                    style={{
                      fontSize: "14px",
                      border: "1px solid white",
                      borderRadius: "4px",
                      background: "transparent",
                      color: "white",
                      padding: "2px 12px",
                      letterSpacing: "1px",
                      fontWeight: 200,
                    }}
                  >
                    Wrong network
                  </button>
                );
              }
              return (
                <div style={{ display: "flex", gap: 12 }}>
                  {/* <button
                    onClick={openChainModal}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      fontSize: "14px",
                      border: "1px solid white",
                      borderRadius: "4px",
                      background: "transparent",
                      color: "white",
                      padding: "2px 12px",
                      letterSpacing: "1px",
                      fontWeight: 200,
                    }}
                    type="button"
                  >
                    {chain.hasIcon && (
                      <div
                        style={{
                          background: chain.iconBackground,
                          width: 12,
                          height: 12,
                          borderRadius: 999,
                          overflow: "hidden",
                          marginBottom: "1px",
                          fontWeight: 200,
                          marginRight: 4,
                          alignSelf: "center",
                        }}
                      >
                        {chain.iconUrl && (
                          <img
                            alt={chain.name ?? "Chain icon"}
                            src={chain.iconUrl}
                            style={{ width: 12, height: 12 }}
                          />
                        )}
                      </div>
                    )}
                    <div>{chain.name.slice(0, 3)}</div>
                  </button> */}

                  <button
                    onClick={() => navigate("/owners-dashboard")}
                    type="button"
                    style={{
                      fontSize: "14px",
                      border: "2px solid #dd4040",
                      borderRadius: "4px",
                      background: "transparent",
                      color: "white",
                      padding: "5px 15px",
                      letterSpacing: "1px",
                      fontWeight: 200,
                      fontFamily: "kremlin",
                    }}
                  >
                    {/* {account.displayName} */}
                    OWNERS DASHBOARD
                  </button>
                  <button
                    onClick={openAccountModal}
                    type="button"
                    style={{
                      fontSize: "14px",
                      border: "2px solid #dd4040",
                      borderRadius: "4px",
                      background: "transparent",
                      color: "white",
                      padding: "2px 12px",
                      letterSpacing: "1px",
                      fontWeight: 200,
                      fontFamily: "kremlin",
                    }}
                  >
                    {/* {account.displayName} */}
                    DC
                  </button>
                </div>
              );
            })()}
          </div>
        );
      }}
    </ConnectButton.Custom>
  );
};
