import React, { useEffect, useState } from "react";
import AOS from "aos";
import { Route, Routes } from "react-router-dom";
import Header from "./components/header";
import Footer from "./components/footer";
import "../src/assets/font/font-awesome.css";
import routes from "./pages";
import Page404 from "./pages/404";

import "@rainbow-me/rainbowkit/styles.css";

import { getDefaultWallets, RainbowKitProvider } from "@rainbow-me/rainbowkit";
import { configureChains, createConfig, WagmiConfig } from "wagmi";
import { mainnet, polygon, optimism, arbitrum, base, zora } from "wagmi/chains";

import { publicProvider } from "wagmi/providers/public";
import { NftProvider } from "./utils/NftProvider";
import { HomeDataProvider } from "./context/HomeContext";

const { chains, publicClient } = configureChains(
  [mainnet, polygon, optimism, arbitrum, base, zora],
  [publicProvider()]
);

const { connectors } = getDefaultWallets({
  appName: "My RainbowKit App",
  projectId: "YOUR_PROJECT_ID",
  chains,
});

const wagmiConfig = createConfig({
  autoConnect: true,
  connectors,
  publicClient,
});

function App() {
  useEffect(() => {
    AOS.init({
      duration: 2000,
    });
  }, []);

  return (
    <>
      <HomeDataProvider>
        <NftProvider>
          <WagmiConfig config={wagmiConfig}>
            <RainbowKitProvider chains={chains}>
              <Header />
              <Routes>
                {routes.map((data, idx) => (
                  <Route
                    key={idx}
                    path={data.path}
                    element={data.component}
                    exact
                  />
                ))}

                <Route path="*" element={<Page404 />} />
              </Routes>

              <Footer />
            </RainbowKitProvider>
          </WagmiConfig>
        </NftProvider>
      </HomeDataProvider>
    </>
  );
}

export default App;
