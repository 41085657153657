import React, { useState } from "react";
import PropTypes from "prop-types";

import SpecItem from "../work-item/SpecItem";

Specs.propTypes = {
  data: PropTypes.array,
};

function Specs(props) {
  const { data, prismicHomeData } = props;

  // console.log("SPECS", prismicHomeData);

  const [dataBlock] = useState({
    subtitle: prismicHomeData.primary.subtitle[0].text,
    title: prismicHomeData.primary.title[0].text,
    desc: prismicHomeData.primary.description[0].text,
  });
  return (
    <section className="tf-section how-we-work">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div
              className="tf-title st2 mb-60"
              data-aos="fade-up"
              data-aos-duration="800"
            >
              <p className="h8 sub-title">{dataBlock.subtitle}</p>
              <h4 className="title m-b17">{dataBlock.title}</h4>
              <p className="p-r60">{dataBlock.desc} </p>
            </div>
          </div>

          {prismicHomeData?.items?.map((item, index) => (
            <div
              key={index}
              className="col-md-6"
              data-aos="fade-up"
              data-aos-delay="100"
              data-aos-duration="800"
            >
              <SpecItem specItem={item} />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default Specs;
