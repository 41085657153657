import React, { useState, useEffect } from "react";

import { Link, NavLink, Router } from "react-router-dom";
import menus from "../../pages/menu";

import "./styles.scss";
import logo from "../../assets/images/logo/logo.png";
import Button from "../button";
import {
  PrismicRichText,
  useFirstPrismicDocument,
  useSinglePrismicDocument,
  PrismicText,
  PrismicImage,
} from "@prismicio/react";

import { ConnectButton } from "@rainbow-me/rainbowkit";
import { ConnectButtonCustom } from "../../utils/ConnectButtonCustom";
import { useAccount } from "wagmi";

const Header = () => {
  const [activeIndex, setActiveIndex] = useState(null);
  const [allowDisconnect, setAllowDisconnect] = useState(false);
  const [menuActive, setMenuActive] = useState(null);
  const [scroll, setScroll] = useState(false);
  const [document] = useSinglePrismicDocument("h");

  let date = new Date("Feb 04 2022").getTime();
  let today = new Date().getTime();
  let msDay = 24 * 60 * 60 * 1000; // milliseconds per day

  let days = Math.floor((today - date) / msDay);

  const [nftsWithMetaData, setNftsWithMetaData] = useState([]);
  const [data, setData] = useState([]);
  const [totalChi, setTotalChi] = useState(0);
  const [totalBalance, setTotalBalance] = useState(0);

  useEffect(() => {
    load()
      .then((data) => {
        if (data) {
          getMetaData(data);
        }
      })
      .then((metaData) => setNftsWithMetaData(metaData));
  }, []);
  const { address, isConnecting, isDisconnected, isConnected } = useAccount();
  if (isConnecting) {
    console.log("Connecting");
  }
  if (isConnected) {
    console.log("Connected");
  }
  if (isDisconnected) {
    console.log("Disconnected");
    // set selectedNftItem in localstorage to null
    // localStorage.setItem("selectedNftItem", null);
  }
  const load = async () => {
    const results = await fetch(
      `https://dev.cryptomonkz.io/api/owner/${address}` //add ${address} for your own wallet
    );
    const data = await results.json();
    setData(data);
    return data;
  };

  const getMetaData = async (ids) => {
    const results = [];
    for (let i = 0; i < ids.length; i++) {
      const id = ids[i];
      const metaData = await fetch(
        `https://dev.cryptomonkz.io/api/metadata/${id}.json`
      );
      const data = await metaData.json();
      results.push(data);
      console.log(results);
    }
    return results;
  };

  const handleShowAccount = (accountNumber) => {
    if (accountNumber.length === 4) {
      return accountNumber;
    } else if (accountNumber.length === 3) {
      return `0${accountNumber}`;
    } else if (accountNumber.length === 2) {
      return `00${accountNumber}`;
    } else if (accountNumber.length === 1) {
      return `000+${accountNumber}`;
    }
  };

  const total = async (e) => {
    let sumChi = 0;
    for (let i = 0; i < nftsWithMetaData?.length; i++) {
      let nft = nftsWithMetaData[i].attributes;
      let currentChiVal = nft.find(({ trait_type }) => trait_type === "Chi");
      let val = currentChiVal.value;
      sumChi += val;
    }
    setTotalChi(sumChi);
    setTotalBalance(sumChi * days);
  };
  useEffect(() => {
    total(nftsWithMetaData);
  }, [nftsWithMetaData]);

  // get the current route of the site
  const currentRoute = window.location.pathname;

  useEffect(() => {
    // console.log(account);

    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 300);
    });
    return () => {
      setScroll({});
    };
  }, []);

  const handleMenuActive = () => {
    setMenuActive(!menuActive);
  };

  const handleDropdown = (index) => {
    setActiveIndex(index);
  };

  return (
    <header id="header_main" className={`header ${scroll ? "is-fixed" : ""}`}>
      <div className="container">
        <div id="site-header-inner">
          <div className="header__logo">
            <NavLink to="/" onClick={() => setAllowDisconnect(false)}>
              {/* <img src={logo} alt="CryptoMonkz" /> */}
              {document && (
                <PrismicImage
                  style={{ width: "138px" }}
                  field={document.data.header_logo}
                ></PrismicImage>
              )}
            </NavLink>
          </div>
          <nav
            id="main-nav"
            className={`main-nav ${menuActive ? "active" : ""}`}
          >
            <ul id="menu-primary-menu" className="menu">
              {menus.map((data, idx) => (
                <li
                  key={idx}
                  onClick={() => handleDropdown(idx)}
                  className={`menu-item ${data.namesub ? "menu-item-has-children" : ""
                    } ${activeIndex === idx ? "active" : ""}`}
                >
                  <Link
                    to={data.links}
                    onClick={() => {
                      setAllowDisconnect(false)
                      handleMenuActive();
                    }}
                  >
                    {data.name}
                  </Link>
                  {data.namesub && (
                    <ul className="sub-menu">
                      {data.namesub.map((submenu) => (
                        <li
                          key={submenu.id}
                          className="menu-item"
                          onClick={() => setAllowDisconnect(false)}
                        >
                          <NavLink to={submenu.links}>{submenu.sub}</NavLink>
                        </li>
                      ))}
                    </ul>
                  )}
                </li>
              ))}
            </ul>
          </nav>
          {/* {account ? (
            <>
              {allowDisconnect ? (
                <Button
                  onClick={() => disconnectWalletFromApp()}
                  title="Disconnect"
                  path="/"
                />
              ) : (
                <div className="tf-button">
                  <ConnectButton accountStatus="avatar" chainStatus="icon" />
                </div>
              )}
            </>
          ) : (
            <Button
              onClick={() => handleWalletConnect()}
              title="Connect Wallet"
              path="/"
            />
          )} */}
          <div className="tf-button no-hover-effect">
            <ConnectButtonCustom />
          </div>

          <div
            className={`mobile-button ${menuActive ? "active" : ""}`}
            onClick={handleMenuActive}
          >
            <span></span>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
