import React, { createContext, useContext, useState } from "react";

export const HomeDataContext = createContext();

export const useHomeData = () => {
  return useContext(HomeDataContext);
};

export const HomeDataProvider = ({ children }) => {
  const [homeData, setHomeData] = useState(null);
  const [loreData, setLoreData] = useState(null);
  const [incubatorData, setIncubatorData] = useState(null);
  const [daikoData, setDaikoData] = useState(null);
  const [shuhariData, setShuhariData] = useState(null);
  const [yokotenkaiData, setYokotenkaiData] = useState(null);
  const [piratesData, setPiratesData] = useState(null);
  const [soheiData, setSoheiData] = useState(null);

  return (
    <HomeDataContext.Provider
      value={{
        homeData,
        setHomeData,
        loreData,
        setLoreData,
        incubatorData,
        setIncubatorData,
        daikoData,
        setDaikoData,
        shuhariData,
        setShuhariData,
        yokotenkaiData,
        setYokotenkaiData,
        piratesData,
        setPiratesData,
        soheiData,
        setSoheiData,
      }}
    >
      {children}
    </HomeDataContext.Provider>
  );
};
