import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import PageTitle from "../pagetitle";
import { Link } from "react-router-dom";
import Team from "../../features/team/home-v2";
import dataTeam from "../../assets/fake-data/data-team";
import Partner from "../../features/partner";
import dataPartner from "../../assets/fake-data/data-partner";

LeaderIntro.propTypes = {
  item: PropTypes.object,
};

function LeaderIntro(props) {
  const { faction, leaderSocials, factionSocials } = props;

  console.log("leader", faction, leaderSocials, factionSocials);

  // console.log("ABOUT ITEM: ", item);

  return (
    <div>
      {faction.length > 1 ? (
        faction?.map((leader, index) => {
          return (
            <section className="tf-section  ">
              <div className="container">
                <div className="row">
                  <div className="col-xl-6 col-lg-6 col-md-6 text-end">
                    <div
                      className="info-detail"
                      data-aos="fade-right"
                      data-aos-duration="800"
                    >
                      <p className="sub">Faction Leader</p>
                      <h4 className="name">
                        {leader?.primary?.leader_title[0].text.toUpperCase()}
                      </h4>

                      {leader?.items?.map((item, index) => {
                        return (
                          <div className="box contact">
                            <div className="h7">
                              {item?.header1?.[0].text.toUpperCase()}
                            </div>
                            <p>{item?.plain_text?.[0]?.text}</p>
                          </div>
                        );
                      })}

                      <ul
                        className="social 
                                d-flex

                                justify-content-end

                                gap-3
                                "
                      >
                        {factionSocials?.items?.map((item, index) => {
                          return (
                            <li>
                              <Link target="_blank" to={item?.social_link?.url}>
                                <img
                                  style={{
                                    width: "40px",
                                    height: "40px",
                                  }}
                                  src={item.social_icon.url}
                                  alt=""
                                />
                              </Link>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                  <div className="col-xl-5 col-lg-5 col-md-5">
                    <div
                      className="image-details"
                      data-aos="fade-left"
                      data-aos-duration="800"
                    >
                      <img src={leader?.primary.leader_image?.url} alt="dsf" />
                    </div>
                  </div>
                </div>
              </div>
            </section>
          );
        })
      ) : (
        <section className="tf-section  ">
          <div className="container">
            <div className="row">
              <div className="col-xl-6 col-lg-6 col-md-6 text-end">
                <div
                  className="info-detail"
                  data-aos="fade-right"
                  data-aos-duration="800"
                >
                  <p className="sub">
                    {faction?.primary?.sub_header?.[0]?.text
                      ? faction?.primary?.sub_header[0].text
                      : "Faction Leader"}
                  </p>
                  <h4 className="name">
                    {faction?.primary?.leader_title[0].text.toUpperCase()}
                  </h4>

                  {faction?.items?.map((item, index) => {
                    return (
                      <div className="box contact">
                        <div className="h7">
                          {item?.header1?.[0].text.toUpperCase()}
                        </div>
                        <p>{item?.plain_text?.[0]?.text}</p>
                      </div>
                    );
                  })}

                  <ul
                    className="social 
                                d-flex

                                justify-content-end

                                gap-3
                                "
                  >
                    {leaderSocials?.items?.map((item, index) => {
                      return (
                        <li
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <Link to="#">
                            <img
                              style={{
                                width: "40px",
                                height: "40px",
                              }}
                              src={item.social_logo.url}
                              alt=""
                            />
                          </Link>
                          <p style={{ textAlign: "center" }}>
                            {item?.alt_text[0].text}
                          </p>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
              <div className="col-xl-5 col-lg-5 col-md-5">
                <div
                  className="image-details"
                  data-aos="fade-left"
                  data-aos-duration="800"
                >
                  <img src={faction?.primary.leader_image?.url} alt="dsf" />
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </div>
  );
}

export default LeaderIntro;
