import React, { useEffect, useState } from "react";

import "../scss/components/section.scss";
import "../scss/components/box.scss";
import Slider from "../components/slider";
import dataSlider from "../assets/fake-data/data-slider";
import About from "../features/about";
import NftCarouselDisplay from "../features/project";
import dataProject from "../assets/fake-data/dataProject";
import dataAbout from "../assets/fake-data/data-about";
import RoadMap from "../features/roadmap/home-v3";
import dataRoadmap from "../assets/fake-data/data-roadmap";
import Work from "../features/work";
import Specs from "../features/work/home-v3";

import dataWork from "../assets/fake-data/data-work";
import Factions from "../features/team";
import dataTeam from "../assets/fake-data/data-team";
import Blog from "../features/blog";
import dataBlog from "../assets/fake-data/data-blog";
// import Partner from "../features/partner";
// import dataPartner from "../assets/fake-data/data-partner";
import FAQ from "../features/faq";
import dataFaq from "../assets/fake-data/data-faq";
import { MutatingDots } from "react-loader-spinner";
import { useHomeData } from "../context/HomeContext";

import {
  PrismicRichText,
  useFirstPrismicDocument,
  useSinglePrismicDocument,
} from "@prismicio/react";

function HomeOne(props) {
  const { homeData, setHomeData } = useHomeData();
  const [prismicHomeData, setPrismicHomeData] = useState(null);
  const [isLoading, setIsLoading] = useState(!homeData); // For handling loading state
  // console.log(prismicHomeData);
  const [document] = useSinglePrismicDocument("homepage");

  // useEffect(() => {
  //   if (document) {
  //     setPrismicHomeData(document);
  //     setIsLoading(false); // Data is loaded
  //   }
  // }, [document]);

  useEffect(() => {
    if (!homeData && document) {
      setHomeData(document);
      setIsLoading(false);
      console.log(homeData);
    }
  }, [document, homeData, setHomeData]);

  if (isLoading) {
    return (
      <div
        // put at the center of the screen
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <MutatingDots
          height="100"
          width="100"
          color="#dd4040"
          secondaryColor="#dd4040"
          radius="12.5"
          ariaLabel="mutating-dots-loading"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
        />
      </div>
    );
  }

  return (
    <div className="home-1">
      <Slider data={dataSlider} />

      {/* ABOUT US SECTION */}
      <About
        data={dataAbout}
        prismicHomeData={homeData.data.body.find(
          (slice) => slice.slice_type === "about_section"
        )}
      />

      {/* NFT DISPLAY (SLIDER) */}
      <NftCarouselDisplay data={dataProject} />

      {/* MONKZ SPECS SECTION */}
      <Specs
        data={dataWork}
        prismicHomeData={homeData.data.body.find(
          (slice) => slice.slice_type === "specs"
        )}
      />

      {/* MONKZ ROADMAP */}
      <RoadMap
        data={dataRoadmap}
        prismicHomeData={homeData.data.body.find(
          (slice) => slice.slice_type === "roadmap"
        )}
      />

      {/* MONKZ FACTIONS */}
      <Factions
        data={dataTeam}
        prismicHomeData={homeData.data.body.find(
          (slice) => slice.slice_type === "factions"
        )}
      />

      {/* HOW TO JOIN */}

      {/* BLOGS AND CONTENT */}
      {/* <Blog data={dataBlog} /> */}

      {/* <Partner data={dataPartner} /> */}

      {/* FAQ SECTION */}
      <FAQ
        data={dataFaq}
        prismicHomeData={homeData.data.body.find(
          (slice) => slice.slice_type === "faq"
        )}
      />
    </div>
  );
}

export default HomeOne;
