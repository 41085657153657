const menus = [
  {
    id: 1,
    name: "Home",
    links: "/",
  },
  {
    id: 3,
    name: "Road Map",
    links: "/road-map-v1",
  },
  {
    id: 7,
    name: "Lore",
    links: "/lore",
  },

  {
    id: 2,
    name: "Incubator",
    links: "/incubator",
  },
  {
    id: 4,
    name: "Factions",
    links: "#",
    namesub: [
      {
        id: 1,
        sub: "daiko",
        links: "/daiko",
      },
      {
        id: 2,
        sub: "shuhari",
        links: "/shuhari",
      },
      {
        id: 3,
        sub: "sohei",
        links: "/sohei",
      },
      {
        id: 4,
        sub: "yokotenkai",
        links: "/yokotenkai",
      },
      {
        id: 5,
        sub: "pirates",
        links: "/pirates",
      },
    ],
  },
  // {
  //   id: 5,
  //   name: "Blog",
  //   links: "#",
  //   namesub: [
  //     {
  //       id: 1,
  //       sub: "Our Blog",
  //       links: "/blog",
  //     },
  //     {
  //       id: 2,
  //       sub: "Blog Details",
  //       links: "/blog-details",
  //     },
  //   ],
  // },
  {
    id: 6,
    name: "Contact",
    links: "/contact",
  },
];

export default menus;
