import React, { createContext, useContext, useState, useEffect } from "react";

const NftContext = createContext();

export const useNft = () => {
  return useContext(NftContext);
};

export const NftProvider = ({ children }) => {
  const [selectedNft, setSelectedNft] = useState(null);

  // when selectedNft is changed, set localstorage nftItem to be the selectedNft

  return (
    <NftContext.Provider value={{ selectedNft, setSelectedNft }}>
      {children}
    </NftContext.Provider>
  );
};
