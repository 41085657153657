const dataAbout = [
  {
    id: 1,
    title: "Unique, 1/1 generated",
    desc: "Gravida viverra dui aliquet eu tortor lorem et gravida. Sed amet phasellus tellus mauris auctor rhoncus. Malesuada nisl at mauris cursus lorem mattis arcu.",
    class: "active",
  },
  {
    id: 2,
    title: "140+ traits in 16 categories",
    desc: "Ornare faucibus urna, netus ut. Ac vulputate ornare donec orci sed gravida senectus. Felis quis morbi vivamus est eget sapien magnis quis.",
  },
  {
    id: 3,
    title: "Usage rights included",
    desc: "Ac, bibendum duis gravida nullam. At amet nibh platea nibh interdum non, egestas bibendum iaculis. Etiam tristique ultricies tristique ullamcorper donec eget odio ut.",
  },
];

export default dataAbout;
