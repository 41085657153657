import React, { useState } from "react";
import PropTypes from "prop-types";
import "./styles.scss";
import { Scrollbar, A11y, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/scss";
import "swiper/scss/navigation";
import "swiper/scss/pagination";
import TeamItem from "./team-item";

Factions.propTypes = {
  data: PropTypes.array,
};

function Factions(props) {
  const { data, prismicHomeData } = props;

  // console.log("data", prismicHomeData);

  const [dataBlock] = useState({
    subtitle: prismicHomeData.primary.subtitle[0].text,
    title: prismicHomeData.primary.title[0].text,
    desc: prismicHomeData.primary.description[0].text,
    text: prismicHomeData.primary.text[0].text,
    desc2: prismicHomeData.primary.description2[0].text,
    text2: prismicHomeData.primary.text2[0].text,
  });
  return (
    <section className="tf-section tf-team">
      <div className="container">
        <div className="row">
          <div className="col-xl-5 col-lg-5 col-md-6 col-12">
            <div
              className="tf-title st2 mb-61"
              data-aos="fade-up"
              data-aos-duration="800"
            >
              <p className="h8 sub-title">{dataBlock.subtitle.toUpperCase()}</p>
              <h4 className="title">{dataBlock.title.toUpperCase()}</h4>
            </div>
            <div
              className="content mobie-30"
              data-aos="fade-up"
              data-aos-duration="800"
            >
              <div className="h7 txt-tranf-cap m-b16">
                {dataBlock.desc.toUpperCase()}
              </div>
              <p>{dataBlock.text}</p>
              <div className="h7 txt-tranf-cap m-b16">
                {dataBlock.desc2.toUpperCase()}
              </div>
              <p>{dataBlock.text2}</p>
            </div>
          </div>
          <div className="col-xl-7 col-lg-7 col-md-6 col-12">
            <div
              className="wrap-team"
              data-aos="fade-up"
              data-aos-duration="800"
            >
              <Swiper
                modules={[Navigation, Scrollbar, A11y]}
                spaceBetween={30}
                observer={true}
                observeParents={true}
                breakpoints={{
                  0: {
                    slidesPerView: 2,
                  },
                  767: {
                    slidesPerView: 1,
                  },
                  991: {
                    slidesPerView: 3,
                  },
                }}
                // navigation
                scrollbar={{ draggable: true }}
              >
                {prismicHomeData.items.map((item, index) => (
                  <SwiperSlide key={index}>
                    <TeamItem item={item} faction={item} />
                  </SwiperSlide>
                ))}
                {prismicHomeData.items.slice(0, 1).map((item, index) => (
                  <div className="hiddenFaction" key={index}>
                    <SwiperSlide>
                      <TeamItem
                        secondRender={true}
                        item={item}
                        faction={item}
                      />
                    </SwiperSlide>
                  </div>
                ))}
              </Swiper>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Factions;
