import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import dataBlog from "../assets/fake-data/data-blog";
import Blog from "../features/blog/home-v2";
import { client } from "../prismic";
// get params
import { useParams } from "react-router-dom";

function LoreDetails(props) {
  const [lore, setLore] = useState(null);
  const { data } = props;
  const params = useParams();

  // fetch lore document by uid from prismic
  useEffect(() => {
    console.log(props, params.id);
    const fetchLore = async () => {
      const response = await client.getByUID("lore", params.id);
      console.log(response);
      setLore(response);
    };

    fetchLore();
  }, []);

  function renderSlices(lore) {
    return lore.data.body.map((slice, index) => {
      const isLastSlice = index === lore.data.body.length - 1;
      return (
        <div className={`content-details ${isLastSlice ? "mb-41" : ""}`}>
          {slice.primary &&
            slice.primary.header &&
            slice.primary.header[0] &&
            slice.primary.header[0].text && (
              <h6>{slice.primary.header[0].text.toUpperCase()}</h6>
            )}

          {slice.items.map((item) => (
            <>
              {item?.basic_text &&
                item?.basic_text[0] &&
                item?.basic_text[0]?.text && <p>{item?.basic_text[0]?.text}</p>}

              {item.list && item.list[0] && item.list[0].text && (
                <ul className="list">
                  {item.list.map((listItem, index) => (
                    <li key={index}>
                      <svg
                        width="8"
                        height="7"
                        viewBox="0 0 8 7"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M0.76 5.108V2.292L2.652 0.4H5.864L7.712 2.336V5.064L5.864 7H2.652L0.76 5.108Z"
                          fill="white"
                        />
                      </svg>
                      {/* Your SVG icon here */}
                      <span>{listItem.text}</span>
                    </li>
                  ))}
                </ul>
              )}
            </>
          ))}
        </div>
      );
    });
  }

  return (
    <div className="post-details">
      <section className="page-title">
        <div className="image">
          <img src={require("../assets/images/post/imgpost10.jpg")} alt="" />
        </div>
      </section>
      <section className="tf-section detail">
        <div className="container">
          <div className="row">
            <article
              className="article"
              data-aos="fade-in"
              data-aos-duration="800"
            >
              <div className="post p-b23">
                <div className="image">
                  <div className="tag st2">Lore</div>
                </div>
                <h4 className="title">
                  <Link to="#">
                    {lore && lore.data.title[0].text.toUpperCase()}
                  </Link>
                </h4>
                <ul className="meta-post">
                  <li>
                    <span>by</span>
                    <Link to="#">
                      {(lore && lore.data.author[0].text) || "author"}
                    </Link>
                  </li>
                  <li>
                    <Link to="#">{lore && lore.data.date[0].text}</Link>
                  </li>
                </ul>
              </div>
              <div className="post-details">
                {lore && <p>{lore.data.description[0].text}</p>}
                <div className="image">
                  <img src={lore && lore.data.lore_creative.url} alt="" />
                </div>
              </div>

              {lore && renderSlices(lore)}

              {/* <div className="wrap-widget">
                <div className="widget widget-tag style-2">
                  <h6 className="title-widget">Tags:</h6>
                  <ul>
                    <li>
                      <Link to="#">Analysis,</Link>
                    </li>
                    <li>
                      <Link to="#">Digital Art,</Link>
                    </li>
                    <li>
                      <Link to="#">Collectible</Link>
                    </li>
                  </ul>
                </div>
                <div className="widget widget-social style-2">
                  <h6 className="title-widget">Share:</h6>
                  <ul>
                    <li>
                      <Link to="#">
                        <i className="fab fa-facebook-f"></i>
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        <i className="fab fa-twitter"></i>
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        <i className="fab fa-instagram"></i>
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        <i className="fab fa-youtube"></i>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div> */}
              {/* <div className="wrap-box-details">
                <div className="previous">
                  <Link className="st" to="#">
                    Previous
                  </Link>
                  <h6>
                    <Link to="#">
                      I Couldn’t Help But Splurge On These Epic Fall Finds
                    </Link>
                  </h6>
                </div>
                <div className="next">
                  <Link className="st" to="#">
                    Next
                  </Link>
                  <h6>
                    <Link to="#">
                      My Mani Photo Dump To Save To Your Nail Inspo Folder
                    </Link>
                  </h6>
                </div>
              </div> */}
            </article>
          </div>
        </div>
      </section>

      {/* <Blog data={dataBlog} /> */}
    </div>
  );
}

export default LoreDetails;
