import React from "react";
import PropTypes from "prop-types";
import "./styles.scss";

WorkItem.propTypes = {
  item: PropTypes.object,
};

function WorkItem(props) {
  const { item } = props;

  // console.log(item);
  return (
    <div
      className={`box-text st2 corner-box ${item.active}`}
      style={{ marginBottom: "20px" }}
    >
      <div className="number">{item.numb[0].text}</div>
      <div className="h7">{item.work_header[0].text.toUpperCase()}</div>
      <p>{item.work_description[0].text}</p>
    </div>
  );
}

export default WorkItem;
