import React, { useEffect, useState } from "react";
import PageTitle from "../components/pagetitle";
import { Link } from "react-router-dom";
import Team from "../features/team/home-v2";
import dataTeam from "../assets/fake-data/data-team";
import Partner from "../features/partner";
import dataPartner from "../assets/fake-data/data-partner";
import FactionIntro from "../components/faction/FactionIntro";
import { useHomeData } from "../context/HomeContext";
import { MutatingDots } from "react-loader-spinner";
import RoadMap from "../features/roadmap/home-v2";
import dataRoadmap from "../assets/fake-data/data-roadmap";
import {
  PrismicRichText,
  useFirstPrismicDocument,
  useSinglePrismicDocument,
} from "@prismicio/react";
import FactionCta from "../components/factionCta";
import { Button } from "react-bootstrap";
import LeaderIntro from "../components/faction/LeaderIntro";
import fetchCryptoMonkzData from "../services/factionData";

function Yokotenkai(props) {
  const { yokotenkaiData, setYokotenkaiData } = useHomeData();
  const [isLoading, setIsLoading] = useState(!yokotenkaiData);
  const [powerCount, setPowerCount] = useState(0);

  const [document] = useSinglePrismicDocument("yokotenkai");

  useEffect(() => {
    if (!yokotenkaiData && document) {
      setYokotenkaiData(document);
      setIsLoading(false);
      console.log("YOKOTENKAIDATA", yokotenkaiData);
    }
  }, [document, yokotenkaiData, setYokotenkaiData]);

  useEffect(() => {
    fetchCryptoMonkzData("Yoko Tenkai").then((result) => {
      console.log("resultd", result);
      setPowerCount(result);
    });
  }, []);

  if (isLoading) {
    return (
      <div
        // put at the center of the screen
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <MutatingDots
          height="200"
          width="200"
          color="#dd4040"
          secondaryColor="#dd4040"
          radius="12.5"
          ariaLabel="mutating-dots-loading"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
        />
      </div>
    );
  }
  return (
    <div>
      <FactionIntro
        faction={yokotenkaiData?.data}
        powerCount={powerCount}
        factionSocials={yokotenkaiData?.data?.body?.find(
          (slice) => slice.slice_type === "footer_socials"
        )}
      />

      {
        // if there is a factionCta slice, render it
        yokotenkaiData.data.body.find(
          (slice) => slice.slice_type === "faction_cta"
        ) && (
          <FactionCta
            data={yokotenkaiData.data.body.find(
              (slice) => slice.slice_type === "faction_cta"
            )}
          />
        )
      }
      {yokotenkaiData.data.body.find(
        (slice) => slice.slice_type === "roadmap"
      ) && (
        <RoadMap
          data={dataRoadmap}
          newData={yokotenkaiData.data.body.find(
            (slice) => slice.slice_type === "roadmap"
          )}
        />
      )}

      {yokotenkaiData.data.body.find(
        (slice) => slice.slice_type === "leader_intro"
      ) && (
        <LeaderIntro
          faction={yokotenkaiData.data.body.find(
            (slice) => slice.slice_type === "leader_intro"
          )}
          factionSocials={yokotenkaiData?.data?.body?.find(
            (slice) => slice.slice_type === "footer_socials"
          )}
          leaderSocials={yokotenkaiData?.data?.body?.find(
            (slice) => slice.slice_type === "leader_socials"
          )}
        />
      )}

      {/* <Team data={dataTeam} />

      <Partner data={dataPartner} /> */}
    </div>
  );
}

export default Yokotenkai;
