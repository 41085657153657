import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import "./styles.scss";
import { useNft } from "../../../utils/NftProvider";
import { useNavigate } from "react-router-dom";

OwnerNft.propTypes = {
  item: PropTypes.object,
};

function OwnerNft(props) {
  const { item } = props;
  const { setSelectedNft } = useNft();
  const navigate = useNavigate();

  const handleItemClick = () => {
    setSelectedNft(item);
    navigate("/nft-item-details");
    // set selectedNftItem on localstorage to be the selectedNft
    localStorage.setItem("selectedNftItem", JSON.stringify(item));
  };

  return (
    <div className="img-box" onClick={handleItemClick}>
      <img src={item.image} alt="MNKZ NFT" />
      <div className="content">{item.name}</div>
    </div>
  );
}

export default OwnerNft;
