import React from "react";
import PropTypes from "prop-types";

RoadMapItem.propTypes = {
  item: PropTypes.object,
};

function RoadMapItem(props) {
  const { item, index } = props;

  const objectives = item.objectives[0].text.split(", ");

  return (
    <div
      key={index}
      className={`rm-box ${index % 2 === 0 ? "left" : "right"}`}
      data-aos="zoom-in"
      data-aos-duration="1200"
    >
      <div className={`corner-box ${item.style}`}>
        <h5>{item.time[0].text}</h5>
        <ul>
          {objectives.map((objective, idx) => (
            <li key={idx}>{objective}</li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default RoadMapItem;
