import React, { useEffect, useState } from "react";

import PageTitle from "../components/pagetitle";
import About from "../features/about/home-v2";
import dataAbout from "../assets/fake-data/data-about";
import Team from "../features/team/home-v2";
import dataTeam from "../assets/fake-data/data-team";
import Partner from "../features/partner";
import dataPartner from "../assets/fake-data/data-partner";
import Blog from "../features/blog";
import dataBlog from "../assets/fake-data/data-blog";
import LoreItem from "../features/blog/blog-item/LoreItem";
import LoreList from "../features/blog/LoreList";
import { client } from "../prismic";
import { useAllPrismicDocumentsByType } from "@prismicio/react";
import { useHomeData } from "../context/HomeContext";
import { MutatingDots } from "react-loader-spinner";

function Lore(props) {
  const { loreData, setLoreData } = useHomeData();
  const [isLoading, setIsLoading] = useState(!loreData);

  const [documents] = useAllPrismicDocumentsByType("lore");

  useEffect(() => {
    if (!loreData && document) {
      setLoreData(documents);
      setIsLoading(false);
      console.log(loreData);
    }
  }, [document, loreData, setLoreData]);

  if (isLoading) {
    return (
      <div
        // put at the center of the screen
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <MutatingDots
          height="200"
          width="200"
          color="#dd4040"
          secondaryColor="#dd4040"
          radius="12.5"
          ariaLabel="mutating-dots-loading"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
        />
      </div>
    );
  }

  return (
    <div className="about">
      <PageTitle title="Lore" />

      <LoreList data={dataBlog} loreList={documents} />
    </div>
  );
}

export default Lore;
