import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import "./styles.scss";

TeamItem.propTypes = {
  item: PropTypes.object,
};

function TeamItem(props) {
  const { item, faction, secondRender } = props;

  // console.log(faction);
  return (
    <div
      className="team-box"
      style={{
        backgroundColor: "#1e0000",
        border: "1px solid #dd4040",
        // if secondRender = true then visibility hidden
        visibility: secondRender ? "hidden" : "visible",
      }}
    >
      <div className="image">
        <img src={faction.faction_image.url} alt="Crybox" />
      </div>
      <div className="content">
        <div className="h8">
          <Link
            to={`/${faction.faction_name[0].text
              .toLowerCase()
              .replace(/\s+/g, "")}`}
          >
            {faction.faction_name[0].text}
          </Link>
        </div>
        <p>{faction.faction_description[0].text}</p>
        {/* <ul className="social">
          {item.listsocial.map((icon) => (
            <li key={icon.id}>
              <Link to={icon.link}>
                <i className={icon.icon}></i>
              </Link>
            </li>
          ))}
        </ul> */}
      </div>
    </div>
  );
}

export default TeamItem;
