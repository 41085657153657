import React, { useEffect, useState } from "react";
import PageTitle from "../components/pagetitle";
import { Link } from "react-router-dom";
import Team from "../features/team/home-v2";
import dataTeam from "../assets/fake-data/data-team";
import Partner from "../features/partner";
import dataPartner from "../assets/fake-data/data-partner";
import FactionIntro from "../components/faction/FactionIntro";
import { useHomeData } from "../context/HomeContext";
import { MutatingDots } from "react-loader-spinner";
import RoadMap from "../features/roadmap/home-v2";
import dataRoadmap from "../assets/fake-data/data-roadmap";
import FactionCta from "../components/factionCta";
import LeaderIntro from "../components/faction/LeaderIntro";
import {
  PrismicRichText,
  useFirstPrismicDocument,
  useSinglePrismicDocument,
} from "@prismicio/react";

import fetchCryptoMonkzData from "../services/factionData";

function Sohei(props) {
  const { soheiData, setSoheiData } = useHomeData();
  const [isLoading, setIsLoading] = useState(!soheiData);
  const [powerCount, setPowerCount] = useState(0);

  const [document] = useSinglePrismicDocument("sohei");

  useEffect(() => {
    if (!soheiData && document) {
      setSoheiData(document);
      setIsLoading(false);
      console.log(soheiData);
    }
  }, [document, soheiData, setSoheiData]);

  useEffect(() => {
    fetchCryptoMonkzData("Sohei").then((result) => {
      console.log("resultd", result);
      setPowerCount(result);
    });
  }, []);

  if (isLoading) {
    return (
      <div
        // put at the center of the screen
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <MutatingDots
          height="200"
          width="200"
          color="#dd4040"
          secondaryColor="#dd4040"
          radius="12.5"
          ariaLabel="mutating-dots-loading"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
        />
      </div>
    );
  }
  return (
    <div>
      <FactionIntro
        faction={soheiData?.data}
        powerCount={powerCount}
        factionSocials={soheiData?.data?.body?.find(
          (slice) => slice.slice_type === "footer_socials"
        )}
      />
      {soheiData.data.body.find(
        (slice) => slice.slice_type === "faction_cta"
      ) && (
        <>
          <FactionCta
            data={soheiData.data.body.find(
              (slice) => slice.slice_type === "faction_cta"
            )}
          />
        </>
      )}
      {soheiData.data.body.find((slice) => slice.slice_type === "roadmap") && (
        <RoadMap
          data={dataRoadmap}
          newData={soheiData.data.body.find(
            (slice) => slice.slice_type === "roadmap"
          )}
        />
      )}

      {soheiData.data.body.find(
        (slice) => slice.slice_type === "leader_intro"
      ) && (
        <LeaderIntro
          faction={soheiData.data.body.find(
            (slice) => slice.slice_type === "leader_intro"
          )}
          leaderSocials={soheiData?.data?.body?.find(
            (slice) => slice.slice_type === "footer_socials"
          )}
        />
      )}

      {/* <Team data={dataTeam} />

      <Partner data={dataPartner} /> */}
    </div>
  );
}

export default Sohei;
