import React, { useState } from "react";
import PropTypes from "prop-types";
import { Dropdown } from "react-bootstrap";

import ProjectItem from "../project-item";
import "../../../scss/components/form.scss";
import Button from "../../../components/button";
import OwnerNft from "../project-item/OwnerNft";

Project.propTypes = {
  data: PropTypes.array,
};

function Project(props) {
  const { data } = props;

  const [visible, setVisible] = useState(16);
  const showMoreItems = () => {
    setVisible((prevValue) => prevValue + 4);
  };

  return (
    <section
      style={{ paddingTop: 15 }}
      className="tf-section tf-project home-2 nft-item"
    >
      <div className="container">
        <div className="row">
          <div className="col-md-4">
            {/* <div
              className="widget widget-search"
              data-aos="fade-in"
              data-aos-duration="800"
            >
              <form action="#">
                <input type="text" placeholder="Search" required="" />
                <button>
                  <i className="fal fa-search"></i>
                </button>
              </form>
            </div> */}
          </div>

          <div className="col-md-8 z-index">
            <div
              className="seclect-box"
              data-aos="fade-in"
              data-aos-duration="800"
            ></div>
          </div>

          {data.slice(0, visible).map((item) => (
            <div key={item.name} className="col-md-3">
              <OwnerNft item={item} />
            </div>
          ))}
          {visible < data.length && (
            <div className="col-md-12">
              <div
                className="btn-about center m-t16"
                data-aos="fade-up"
                data-aos-delay="300"
                data-aos-duration="800"
              >
                <Button title="Load More" path="#" onClick={showMoreItems} />
              </div>
            </div>
          )}
        </div>
      </div>
    </section>
  );
}

export default Project;
