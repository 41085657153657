const dataWork = [
  {
    id: 1,
    numb: "01",
    title: "Set up your wallet",
    text: "Arcu morbi sit laoreet semper ultrices maecenas auctor amet. Donec tortor facilisis risus, neque sit aliquet orci, malesuada.",
    active: "active",
  },
  {
    id: 2,
    numb: "02",
    title: "Buy your collection",
    text: "Arcu morbi sit laoreet semper ultrices maecenas auctor amet. Donec tortor facilisis risus, neque sit aliquet orci, malesuada.",
  },
  {
    id: 3,
    numb: "03",
    title: "Add your NFT's",
    text: "Arcu morbi sit laoreet semper ultrices maecenas auctor amet. Donec tortor facilisis risus, neque sit aliquet orci, malesuada.",
  },
  {
    id: 4,
    numb: "04",
    title: "Sell Your NFT's",
    text: "Arcu morbi sit laoreet semper ultrices maecenas auctor amet. Donec tortor facilisis risus, neque sit aliquet orci, malesuada.",
  },
];
export default dataWork;
