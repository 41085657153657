import React from "react";
import PropTypes from "prop-types";
import { Accordion } from "react-bootstrap-accordion";

AccordionItem.propTypes = {
  item: PropTypes.object,
};

function AccordionItem(props) {
  const { item } = props;

  //   console.log(item);

  return (
    <Accordion key={item.id} title={item.button_text[0].text} show={item.show}>
      <p className="toggle-content">{item.description[0].text}</p>
    </Accordion>
  );
}

export default AccordionItem;
