import React, { useEffect, useState } from "react";
import PageTitle from "../components/pagetitle";
import { useAccount } from "wagmi";
import fetchCryptoMonkzData from "../services/factionData";
import { fetchMonkNfts } from "../services/factionData";

function Contact(props) {
  const [data, setData] = useState([]); //data from api
  const { address, isConnecting, isDisconnected, isConnected } = useAccount();

  useEffect(() => {
    fetchMonkNfts()
      .then((result) => {
        console.log("resultd", result);
      })
      .catch((err) => console.error(err));

    fetchCryptoMonkzData("Daiko").then((factionData) => {
      load(factionData);
    });
  }, []);

  const load = async (faction) => {
    console.log("faction in load", faction);
    const results = await fetch(
      `https://dev.cryptomonkz.io/api/owner/${address}`
    );
    const data = await results.json();
    console.log("data", data);
    setData(data);
    return getMetaData(data, "Daiko");
  };

  const getMetaData = async (ids, faction) => {
    const results = [];
    for (let i = 0; i < ids.length; i++) {
      const id = ids[i];
      const metaData = await fetch(
        `https://dev.cryptomonkz.io/api/metadata/${id}.json`
      );
      const data = await metaData.json();
      // Check if the NFT belongs to the specified faction
      if (
        data.attributes.some(
          (attr) => attr.trait_type === "Faction" && attr.value === faction
        )
      ) {
        results.push(data);
      }
      console.log("Filtered NFTs: ", results);
    }
    return results;
  };

  return (
    <div>
      <PageTitle title="Contact Us" />

      <section className="tf-section tf-contact">
        <div className="container">
          <div className="row">
            <div className="col-xl-5 col-md-12">
              <div
                className="content-about m-b50 mobie-40"
                data-aos="fade-right"
                data-aos-duration="800"
              >
                <div className="tf-title st2 m-b17">
                  <h4 className="title">Have a question </h4>
                </div>
                <p className="m-r-40">
                  Fill out the Form and our team will get back to you
                </p>
              </div>
              <form
                action="contact/contact-process.php"
                className="form-contact"
                id="contactform"
                data-aos="fade-right"
                data-aos-duration="800"
              >
                <fieldset>
                  <input type="text" name="name" id="name" placeholder="Name" />
                </fieldset>
                <fieldset>
                  <input
                    type="email"
                    name="mail"
                    id="mail"
                    placeholder="Email Address"
                  />
                </fieldset>
                <fieldset>
                  <input
                    type="number"
                    name="phone"
                    id="phone"
                    placeholder="Phone"
                  />
                </fieldset>
                <fieldset>
                  <textarea
                    placeholder="Type your Messege"
                    rows="5"
                    tabIndex="4"
                    name="message"
                    className="message"
                    id="message"
                  ></textarea>
                </fieldset>
                <button className="tf-button btn-effect" type="submit">
                  <span className="boder-fade"></span>
                  <span className="effect">Send Message</span>
                </button>
              </form>
            </div>
            <div className="col-xl-7  col-md-12">
              <div
                className="contact-details"
                data-aos="fade-left"
                data-aos-duration="800"
              >
                <div className="adress wrap-fx">
                  <div className="location">
                    <h6>Location</h6>
                    <ul>
                      <li>The CRYPTO-VERSE</li>
                    </ul>
                  </div>
                  <div className="mail">
                    <h6>Contact Us</h6>
                    <ul>
                      <li>help@cryptomonkz.io</li>
                      <li>info@cryptomonkz.io</li>
                    </ul>
                  </div>
                </div>
                {/* <div
                  className="flat-map wow fadeIn animated"
                  data-wow-delay="0.3ms"
                  data-wow-duration="1000ms"
                >
                  <iframe
                    title="map"
                    className="map-content"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4457.30210514409!2d144.9550716623184!3d-37.818421643591336!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad65d4dd5a05d97%3A0x3e64f855a564844d!2s121%20King%20St%2C%20Melbourne%20VIC%203000%2C%20%C3%9Ac!5e0!3m2!1svi!2s!4v1631871760998!5m2!1svi!2s"
                    width="1720"
                    height="655"
                    allowFullScreen=""
                    loading="lazy"
                  />
                </div> */}
                <div>
                  {/* image */}
                  <img
                    src={require("../assets/images/background/4.png")}
                    alt=""
                    style={{ width: "100%" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Contact;
