import React from "react";
import PropTypes from "prop-types";

import PageTitle from "../pagetitle";
import { Link } from "react-router-dom";
import Team from "../../features/team/home-v2";
import dataTeam from "../../assets/fake-data/data-team";
import Partner from "../../features/partner";
import dataPartner from "../../assets/fake-data/data-partner";

FactionIntro.propTypes = {
  item: PropTypes.object,
};

function FactionIntro(props) {
  const { faction, powerCount, factionSocials } = props;

  // console.log("FACTION: ", faction);

  // console.log("ABOUT ITEM: ", item);

  return (
    <div>
      <PageTitle
        title={faction?.header?.[0]?.text.toUpperCase()}
        powerCount={powerCount}
      />
      <section className="tf-section team-detail ">
        <div className="container">
          <div className="row">
            <div className="col-xl-5 col-lg-5 col-md-5">
              <div
                className="image-details"
                data-aos="fade-right"
                data-aos-duration="800"
              >
                <img src={faction?.factionlogo?.url} alt="dsf" />
              </div>
            </div>
            <div className="col-xl-7 col-lg-7 col-md-7">
              <div
                className="info-detail"
                data-aos="fade-left"
                data-aos-duration="800"
              >
                <p className="sub">
                  {faction?.subname?.[0].text.toUpperCase()}
                </p>
                <h4 className="name">
                  {faction?.mainname?.[0].text.toUpperCase()}
                </h4>
                <div className="box">
                  {faction.body.find(
                    (slice) => slice.slice_type === "faction_description"
                  ) && (
                    <div className="h7">
                      {faction.body
                        .find(
                          (slice) => slice.slice_type === "faction_description"
                        )
                        ?.primary?.description?.[0]?.text.toUpperCase()}
                    </div>
                  )}
                  {faction.body
                    .find((slice) => slice.slice_type === "faction_description")
                    ?.items?.map((item, index) => {
                      return <p key={index}>{item?.content?.[0]?.text}</p>;
                    })}
                </div>
                <div className="box contact">
                  {faction.body.find(
                    (slice) => slice.slice_type === "faction_mission_statement"
                  ) && (
                    <div className="h7">
                      {faction.body
                        .find(
                          (slice) =>
                            slice.slice_type === "faction_mission_statement"
                        )
                        ?.primary?.mission_statement?.[0]?.text.toUpperCase()}
                    </div>
                  )}
                  {faction.body
                    .find(
                      (slice) =>
                        slice.slice_type === "faction_mission_statement"
                    )
                    ?.items?.map((item, index) => {
                      return <p key={index}>{item?.content?.[0]?.text}</p>;
                    })}
                </div>
                <ul className="social">
                  {factionSocials?.items?.map((item, index) => {
                    return (
                      <li>
                        <Link target="_blank" to={item?.social_link?.url}>
                          <img
                            style={{
                              width: "40px",
                              height: "40px",
                            }}
                            src={item.social_icon.url}
                            alt=""
                          />
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default FactionIntro;
