import img1 from "../mnkz/img1.png";
import img22 from "../mnkz/img22.png";
import img3 from "../mnkz/img3.png";
import img4 from "../mnkz/img4.png";
import img6 from "../mnkz/img6.png";
import img7 from "../mnkz/img7.png";
import img9 from "../mnkz/img9.png";
import img16 from "../mnkz/img16.png";
import img17 from "../mnkz/img17.png";
import img18 from "../mnkz/img18.png";
import img19 from "../mnkz/img19.png";
import img20 from "../mnkz/img20.png";
import img23 from "../mnkz/img3.png";
import img24 from "../mnkz/img24.png";
import img25 from "../mnkz/img25.png";

const dataProject = [
  {
    id: 1,
    title: "CRYPTO MONKZ #01",
    img: img1,
  },
  {
    id: 2,
    title: "CRYPTO MONKZ #02",
    img: img22,
  },
  {
    id: 3,
    title: "CRYPTO MONKZ #03",
    img: img3,
  },
  {
    id: 4,
    title: "CRYPTO MONKZ #04",
    img: img4,
  },
  {
    id: 5,
    title: "CRYPTO MONKZ #05",
    img: img25,
  },
  {
    id: 6,
    title: "CRYPTO MONKZ #06",
    img: img6,
  },
  {
    id: 7,
    title: "CRYPTO MONKZ #07",
    img: img7,
  },
  {
    id: 8,
    title: "CRYPTO MONKZ #08",
    img: img20,
  },

  {
    id: 9,
    title: "CRYPTO MONKZ #09",
    img: img9,
  },
  {
    id: 10,
    title: "CRYPTO MONKZ #10",
    img: img16,
  },
  {
    id: 11,
    title: "CRYPTO MONKZ #11",
    img: img17,
  },
  {
    id: 12,
    title: "CRYPTO MONKZ #12",
    img: img18,
  },
  {
    id: 13,
    title: "CRYPTO MONKZ #13",
    img: img19,
  },
  {
    id: 14,
    title: "CRYPTO MONKZ #14",
    img: img20,
  },
  {
    id: 15,
    title: "CRYPTO MONKZ #15",
    img: img1,
  },
  {
    id: 16,
    title: "CRYPTO MONKZ #16",
    img: img24,
  },
  {
    id: 17,
    title: "CRYPTO MONKZ #01",
    img: img3,
  },
  {
    id: 18,
    title: "CRYPTO MONKZ #02",
    img: img22,
  },
  {
    id: 19,
    title: "CRYPTO MONKZ #03",
    img: img16,
  },
  {
    id: 20,
    title: "CRYPTO MONKZ #04",
    img: img18,
  },
  {
    id: 21,
    title: "CRYPTO MONKZ #05",
    img: img19,
  },
  {
    id: 22,
    title: "CRYPTO MONKZ #06",
    img: img20,
  },
  {
    id: 23,
    title: "CRYPTO MONKZ #07",
    img: img23,
  },
  {
    id: 24,
    title: "CRYPTO MONKZ #08",
    img: img3,
  },
];

export default dataProject;
