import React, { useState } from 'react';
import PropTypes from 'prop-types';
import '../styles.scss'


import { Scrollbar, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/scss';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';

RoadMap.propTypes = {
    data: PropTypes.array,
};

function RoadMap(props) {

    const { data, newData } = props;

    const [dataBlock] = useState(
        {
            subtitle: 'ROADMAP',
            title: 'Faction Timeline'
        }
    )

    return (
        <section className="tf-section tf-roadmap style2">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className="tf-title mb-30" data-aos="fade-up" data-aos-duration="800">
                            <p className="h8 sub-title">{dataBlock.subtitle}</p>
                            <h4 className="title">{dataBlock.title}</h4>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="roadmap style2" data-aos="fade-up" data-aos-duration="800">
                            <Swiper
                                modules={[Scrollbar, A11y]}
                                spaceBetween={60}
                                scrollbar={{ draggable: true }}
                                breakpoints={{
                                    0: {
                                        slidesPerView: 1,
                                    },
                                    767: {
                                        slidesPerView: 2,
                                    },
                                    991: {
                                        slidesPerView: 3,
                                    },
                                }}
                            >
                                {
                                    newData?.items?.map((item, index) => {
                                        const objectives = item.objectives[0].text.split(", ");
                                        console.log('item', item)

                                        return (
                                            <SwiperSlide key={index}>
                                                <div className="box">
                                                    <div className="rm-box">
                                                        <div className={`corner-box ${item.style}`}>
                                                            <h5>{item?.time[0]?.text}</h5>
                                                            <ul>
                                                                {objectives.map((objective, idx) => (
                                                                    <li key={idx}>{objective}</li>
                                                                ))}
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className="icon"></div>
                                                </div>
                                            </SwiperSlide>
                                        )

                                    })
                                }
                            </Swiper>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default RoadMap;