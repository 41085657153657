import React, { useState } from "react";
import PropTypes from "prop-types";
import "./styles.scss";
import Button from "../../components/button";
import AboutItem from "./about-item";
import IncubatorDetailItem from "./about-item/IncubatorDetailItem";

IncubatorDetails.propTypes = {
  data: PropTypes.array,
};

function IncubatorDetails(props) {
  const { data, details: prismicHomeData } = props;
  // console.log("ABOUT", prismicHomeData.items);
  console.log(prismicHomeData);

  const [dataBlock] = useState({
    subtitle:
      prismicHomeData?.data.intro_sub_head[0].text.toUpperCase() ||
      "elevate yourself",
    title:
      prismicHomeData?.data.intro_head[0].text.toUpperCase() ||
      "Learn how we help businesses grow",
    desc:
      prismicHomeData?.data.intro_descriptionone[0].text ||
      "sLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    desc2:
      prismicHomeData?.data.intro_description_two[0].text ||
      "sssLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    btnText: prismicHomeData?.data.btn_cta[0].text || "LEARN MORE",
  });

  return (
    <section className="tf-section tf-about">
      <div className="container">
        <div className="row">
          <div className="">
            <div
              className="content-about mobie-40"
              data-aos="fade-up"
              data-aos-duration="800"
              style={{ textAlign: "center" }}
            >
              <div className="tf-title st2">
                <p className="h8 sub-title">
                  {dataBlock?.subtitle.toUpperCase()}
                </p>
                <h4 className="title">{dataBlock?.title.toUpperCase()}</h4>
              </div>
              <p>{dataBlock?.desc.toUpperCase()}</p>
              <p>{dataBlock?.desc2.toUpperCase()}</p>
              <Button title={dataBlock?.btnText} path="https://discord.gg/y5vRXAbq" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default IncubatorDetails;
