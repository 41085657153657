import React, { useState } from "react";
import PropTypes from "prop-types";
import "./styles.scss";
import Button from "../../components/button";
import AboutItem from "./about-item";

About.propTypes = {
  data: PropTypes.array,
};

function About(props) {
  const { data, prismicHomeData } = props;
  // console.log("ABOUT", prismicHomeData.items);

  const [dataBlock] = useState({
    subtitle: prismicHomeData.primary.subtitle[0].text.toUpperCase(),
    title: prismicHomeData.primary.title[0].text.toUpperCase(),
    desc: prismicHomeData.primary.description[0].text,
    btnText: prismicHomeData.primary.button[0].text.toUpperCase(),
  });

  return (
    <section className="tf-section tf-about">
      <div className="container">
        <div className="row">
          <div className="col-xl-5 col-md-12">
            <div
              className="content-about mobie-40"
              data-aos="fade-up"
              data-aos-duration="800"
            >
              <div className="tf-title st2" style={{ textAlign: "left" }}>
                <p className="h8 sub-title">
                  {dataBlock.subtitle.toUpperCase()}
                </p>
                <h4 className="title">{dataBlock.title.toUpperCase()}</h4>
              </div>
              <p>{dataBlock.desc}</p>
              <Button title={dataBlock.btnText.toUpperCase()} path="/" />
            </div>
          </div>
          <div className="col-xl-7 col-md-12">
            <div
              className="wrap-about"
              data-aos="fade-up"
              data-aos-duration="800"
            >
              {prismicHomeData?.items?.map((item, index) => (
                <AboutItem key={index} item={item} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default About;
