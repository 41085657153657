import React, { useEffect, useState } from "react";
import PageTitle from "../components/pagetitle";
import { Link } from "react-router-dom";
import Team from "../features/team/home-v2";
import dataTeam from "../assets/fake-data/data-team";
import Partner from "../features/partner";
import dataPartner from "../assets/fake-data/data-partner";
import FactionIntro from "../components/faction/FactionIntro";
import { useHomeData } from "../context/HomeContext";
import { MutatingDots } from "react-loader-spinner";
// import RoadMap from "../components/features/roadmap/home-v2";
import RoadMap from "../features/roadmap/home-v2";
import dataRoadmap from "../assets/fake-data/data-roadmap";
import Button from "../components/button";
import FactionCta from "../components/factionCta";
import LeaderIntro from "../components/faction/LeaderIntro";
import fetchCryptoMonkzData from "../services/factionData";
import {
  PrismicRichText,
  useFirstPrismicDocument,
  useSinglePrismicDocument,
} from "@prismicio/react";

function Daiko(props) {
  const { daikoData, setDaikoData } = useHomeData();
  const [isLoading, setIsLoading] = useState(!daikoData);
  const [powerCount, setPowerCount] = useState(0);

  const [document] = useSinglePrismicDocument("daiko");

  useEffect(() => {
    if (!daikoData && document) {
      setDaikoData(document);
      setIsLoading(false);
      console.log("daikoData", daikoData);
    }
  }, [document, daikoData, setDaikoData]);

  useEffect(() => {
    // console.log("on doako page");
    // console.log("daikoData", daikoData);
    fetchCryptoMonkzData("Daiko").then((result) => {
      console.log("resultd", result);
      setPowerCount(result);
    });
  }, []);

  if (isLoading) {
    return (
      <div
        // put at the center of the screen
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <MutatingDots
          height="200"
          width="200"
          color="#dd4040"
          secondaryColor="#dd4040"
          radius="12.5"
          ariaLabel="mutating-dots-loading"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
        />
      </div>
    );
  }

  return (
    <div>
      <FactionIntro
        faction={daikoData?.data}
        powerCount={powerCount}
        factionSocials={daikoData?.data?.body?.find(
          (slice) => slice.slice_type === "footer_socials"
        )}
      />

      {
        // if there is a factionCta slice, render it
        daikoData.data.body.find(
          (slice) => slice.slice_type === "faction_cta"
        ) && (
          <>
            <FactionCta
              data={daikoData.data.body.find(
                (slice) => slice.slice_type === "faction_cta"
              )}
            />
          </>
        )
      }

      {/* <FactionCta /> */}
      {daikoData.data.body.find((slice) => slice.slice_type === "roadmap") && (
        <RoadMap
          data={dataRoadmap}
          newData={daikoData.data.body.find(
            (slice) => slice.slice_type === "roadmap"
          )}
        />
      )}

      {daikoData.data.body.find(
        (slice) => slice.slice_type === "leader_intro"
      ) && (
        <LeaderIntro
          faction={daikoData.data.body.find(
            (slice) => slice.slice_type === "leader_intro"
          )}
          leaderSocials={daikoData?.data?.body?.find(
            (slice) => slice.slice_type === "footer_socials"
          )}
        />
      )}
      {/* <Team data={dataTeam} />

      <Partner data={dataPartner} /> */}
    </div>
  );
}

export default Daiko;
