// function to get the data from the API
const fetchCryptoMonkzData = async (faction) => {
  console.log(
    "fetching data",
    process.env.REACT_APP_OPEN_SEA_KEY,
    process.env.REACT_APP_PRISMIC_TOKEN
  );
  const options = {
    method: "GET",
    headers: {
      accept: "application/json",
      "x-api-key": process.env.REACT_APP_OPEN_SEA_KEY,
    },
  };

  let power;
  await fetch(
    "https://api.opensea.io/api/v2/traits/cryptomonkz-official",
    options
  )
    .then((res) => res.json())
    .then((result) => {
      console.log("result", result);
      const factionData = result.counts.Faction[faction];
      console.log("factionData", factionData);
      power = factionData;
      return factionData;
    })
    .catch((err) => console.error(err));

  return power;
};

export const fetchMonkNfts = async () => {
  const options = {
    method: "GET",
    headers: {
      accept: "application/json",
      "x-api-key": process.env.REACT_APP_OPEN_SEA_KEY,
    },
  };

  let power;
  await fetch(
    "https://api.opensea.io/api/v2/traits/cryptomonkz-official?search[stringTraits][0][name]=Faction&search[stringTraits][0][values][0]=Sohei",
    options
  )
    .then((res) => res.json())
    .then((result) => {
      console.log("GETTING NFTS", result);
      return result;
    })
    .catch((err) => console.error(err));

  return power;
};

export default fetchCryptoMonkzData;
