import React, { useState, useEffect } from "react";
import dataRoadmap from "../assets/fake-data/data-roadmap";
import PageTitle from "../components/pagetitle";
import RoadMap from "../features/roadmap";
import { MutatingDots } from "react-loader-spinner";
import { useHomeData } from "../context/HomeContext";

import {
  PrismicRichText,
  useFirstPrismicDocument,
  useSinglePrismicDocument,
} from "@prismicio/react";

function RoadMapOne(props) {
  const { homeData, setHomeData } = useHomeData();

  const [isLoading, setIsLoading] = useState(true);
  const [document] = useSinglePrismicDocument("homepage");

  useEffect(() => {
    if (!homeData) {
      setHomeData(document);
      setIsLoading(false); // Data is loaded
    } else {
      setIsLoading(false);
    }
  }, [document]);

  if (isLoading) {
    return (
      <div
        // put at the center of the screen
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <MutatingDots
          height="200"
          width="200"
          color="#dd4040"
          secondaryColor="#dd4040"
          radius="12.5"
          ariaLabel="mutating-dots-loading"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
        />
      </div>
    );
  }

  return (
    <div className="page-roadmap">
      <PageTitle title="Roadmap" />

      <RoadMap
        // data={dataRoadmap}
        prismicHomeData={homeData?.data?.body.find(
          (slice) => slice.slice_type === "roadmap"
        )}
      />
    </div>
  );
}

export default RoadMapOne;
